.about__container {
  display: flex;
  padding: 0;
  width: 100%;
  background-image: linear-gradient(to bottom right, var(--black-color) 50%, var(--primary-color) 50%);
}

.app__about {
  flex: 1;
  display: flex;
  width: 100%;  
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-content: space-between;

      h2 {
        font-size: 1.45rem;
      }
    }
}

.app__profile {
  display: flex;
  width: 500px;
  white-space: normal;
  margin-top: 20rem;
  @media screen and (max-width: 900px) {
    margin-top: 26rem;
    margin-left: 111px;
    height: 275px;
    width: 245px;
  }
}
