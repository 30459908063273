.app__projects {
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 1rem;

  @media screen and (max-width: 900px) {
    padding-right: 0;
  }
}
.app__project-header {
  display: flex;
  width: 100%;
  justify-self: flex-start;
  justify-content: space-between;
  margin: 4rem 0 2rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    }
}

.app__project-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;


  .app__project-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (max-width: 900px) {
      padding: 5px 10px;

    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__project-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  .app__project-item {
    max-width: 100%;
    flex-direction: row;
    min-height: fit-content;

    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 400px) {
      width: 360px;
      margin: 1rem;
      flex-direction: column;;
    }
  }
}

.app__project-img {
  width: 100%;
  height: 230px;

  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (max-width: 900px) {
    height: 100px;
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__project-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--primary-color);
    }

    &:hover {
      svg {
          color: var(--green-color);
        }
    }
  }
}

.app__project-content {
  padding: 0.5rem;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: center;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
    padding-bottom: 1rem;
  
    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    } 
  }

  .app__project-description {
    height: 100%;
    padding-bottom: 0.5rem;
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  .app__project-tag-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: flex-start;
    gap: 0.25rem;
  }

  .app__project-tag {
    padding: 0.30rem 0.5rem;
    width: fit-content;
    border-radius:4px;
    color: var(--black-color);
    background-color: rgba(224, 202, 60, 0.45);
  }

  @media screen and (max-width: 400px) {
    justify-content: space-evenly;
  }
}